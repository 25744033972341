<template>
  <div class="chats">
    <div
      v-for="(msgGrp, index) in chatData"
      :key="index"
      class="chat"
      :class="msgGrp.commented_by_id === userData.id ? '' : 'chat-left'"
    >
      <div class="chat-avatar">
        <b-avatar size="4em">
          {{ msgGrp.commented_by_name }}
        </b-avatar>
      </div>
      <div class="chat-body">
        <div
          class="chat-content"
        >
          <p>{{ msgGrp.content }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BAvatar } from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BAvatar,
  },
  props: {
    chatData: {
      type: Array,
      required: true,
    },
    // profileUserAvatar: {
    //   type: String,
    //   required: true,
    // },
  },
  data() {
    return {
      userData: getUserData(),
    }
  },
  // setup(props) {
  //   const formattedChatData = computed(() => {
  //     const contact = {
  //       id: props.chatData.id,
  //     }
  //
  //     let chatLog = []
  //     if (props.chatData.chat) {
  //       chatLog = props.chatData.chat.chat
  //     }
  //
  //     const formattedChatLog = []
  //     let chatMessageSenderId = chatLog[0] ? chatLog[0].senderId : undefined
  //     let msgGroup = {
  //       sender: chatMessageSenderId,
  //       messages: [],
  //     }
  //
  //     chatLog.forEach((msg, index) => {
  //       if (chatMessageSenderId === msg.senderId) {
  //         msgGroup.messages.push({
  //           msg: msg.message,
  //           time: msg.time,
  //         })
  //       } else {
  //         chatMessageSenderId = msg.senderId
  //         formattedChatLog.push(msgGroup)
  //         msgGroup = {
  //           senderId: msg.senderId,
  //           messages: [{
  //             msg: msg.message,
  //             time: msg.time,
  //           }],
  //         }
  //       }
  //       if (index === chatLog.length - 1) formattedChatLog.push(msgGroup)
  //     })
  //
  //     return {
  //       formattedChatLog,
  //       contact,
  //       profileUserAvatar: props.profileUserAvatar,
  //     }
  //   })
  //
  //   return {
  //     formattedChatData,
  //   }
  // },
}
</script>

<style>

</style>
