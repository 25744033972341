<template>
  <component
    :is="tag"
    v-on="$listeners"
  >
    <b-avatar
      size="42"
    >{{ task.title }}</b-avatar>
    <div class="chat-info flex-grow-1">
      <h5 class="mb-0">
        {{ task.title }}
      </h5>
      <p class="card-text text-truncate">
        {{ task.start_date }}
      </p>
    </div>
    <div
      class="chat-meta text-nowrap"
    >
      <small class="float-right mb-25 chat-time">{{ task.task_type.task_type_name }}</small>
      <b-badge
        pill
        variant="primary"
        class="float-right"
      >
        {{ task.days_remaining }}
      </b-badge>
    </div>
  </component>
</template>

<script>
import { BAvatar, BBadge } from 'bootstrap-vue'
import { formatDateToMonthShort } from '@core/utils/filter'
import useChat from './useChat'

export default {
  components: {
    BAvatar,
    BBadge,
  },
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    task: {},
    isChatContact: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { resolveAvatarBadgeVariant } = useChat()
    return { formatDateToMonthShort, resolveAvatarBadgeVariant }
  },
}
</script>

<style>

</style>
